.full-post {
  width: 80%;
  max-width: 600px;
  background-color: #0d1114;
  padding: 20px;
  border-radius: 0 0 0 0;
  margin: 0 auto;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
  margin-top: 48px;
}

@media screen and (max-width: 768px) {
  .full-post {
    border-left: none;
    border-right: none;
  }
}

@media screen and (min-width: 768px) {
  .full-post {
    width: 70%;
  }
}

@media screen and (min-width: 1024px) {
  .full-post {
    width: 90%;
    max-width: 2048px;
    padding: 20px 0;
    min-width: 900px; /* Add minimum width to prevent container from getting too narrow */
    min-height: 100vh;
  }

  .full-post-content {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    min-width: 0; /* Allow flex items to shrink below their minimum content size */
    height: calc(100vh - 90px); /* Account for padding and margins */
  }

  .metadata-container {
    width: 85%;
    padding: 0 20px;
    min-width: 300px; /* Minimum width for readable text */
    flex-shrink: 1;
  }

  .pdf-container {
    padding: 0 20px;
    margin: 0;
    min-width: 500px; /* Minimum width for PDF viewer */
    flex-shrink: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

.full-post .post-header {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}

.full-post .post-title {
  font-size: 1em;
  color: white;
  text-align: left;
  margin-bottom: 0;
}

.full-post .post-authors {
  font-size: 0.8em;
  color: white;
  text-align: left;
  font-style: italic;
  margin-top: 0;
}

.full-post .post-abstract.full {
  font-size: 0.9em;
  color: white;
  text-align: left;
  margin-top: 10px;
  line-height: 1.5;
  -webkit-line-clamp: unset;
  line-clamp: unset;
  display: block;
  overflow: visible;
  white-space: normal;
  margin-bottom: 20px; /* Add margin to create space above PDF viewer */
}

.full-post .post-subjects {
  display: flex;
  gap: 5px;
}

.full-post .post-subject-pill {
  background-color: rgb(25, 50, 100);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9em;
}

.full-post .post-date-pill {
  background-color: #0d1114;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9em;
}

/* Add new styles for PDF viewer container */
.full-post .pdf-container {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 10px;
}