.app-header {
    background-color: #0d1114;
    color: white;
    padding: 10px;
    padding-left: 20px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
    text-align: left;
    box-sizing: border-box;
    font-family: 'Helvetica', sans-serif;
    border-bottom: 1px solid gray;
}

h1 {
    font-weight: 300;
    margin: 0;
    font-size: 1.5em;
    /* Reduced font size */
}

body,
html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}