.pdf-viewer-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    min-height: 450px;
    background-color: #f5f5f5;
}

.pdf-viewer-container {
    position: relative;
    width: 100%;
    height: calc(100vh - 40px); /* Adjust height to viewport minus some padding */
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
    padding: 20px 0;
}

.page-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
}

.pdf-page {
    width: 100% !important;
    background-color: white;
}

.pdf-error {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    color: #666;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    font-size: 16px;
}


.comment-button {
    position: sticky;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    margin-top: -60px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #2563eb;
    color: white;
    border: none;
    border-radius: 24px;
    padding: 12px 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    font-size: 14px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    z-index: 1100;
    max-width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.comment-button:hover {
    background-color: #1d4ed8;
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.comment-icon {
    color: white;
}

.comment-text {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.pdf-loading,
.page-loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    color: #666;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
    gap: 12px;
}

.pdf-loading {
    height: 200px;
}

.page-loading {
    background-color: #f8f8f8;
    border-radius: 4px;
    margin: 10px 0;
    height: 100px;
}

.loading-spinner {
    width: 24px;
    height: 24px;
    border: 2px solid #f3f3f3;
    border-top: 2px solid #666;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .comment-button {
        font-size: 12px;
        padding: 6px 10px;
    }

    .page-wrapper {
        margin: 10px auto;
    }
}