.post {
  width: 80%;
  /* Default for mobile */
  max-width: 600px;
  background-color: #0d1114;
  padding-top: 20px;
  border-radius: 0 0 0 0;
  height: auto;
  border-bottom: 1px solid grey;
  border-left: 1px solid grey;
  border-right: 1px solid grey;
}

@media screen and (max-width: 768px) {
  .post {
    border-left: none;
    border-right: none;
  }
}

/* For tablets and small laptops */
@media screen and (min-width: 768px) {
  .post {
    width: 70%;
  }
}

/* For larger laptops and desktops */
@media screen and (min-width: 1024px) {
  .post {
    width: 60%;
  }
}

.post-header {
  display: flex;
  justify-content: space-between;
  font-size: 0.8em;
}

.post-title {
  font-size: 1em;
  color: white;
  text-align: left;
  margin-bottom: 0;
}

.post-authors {
  font-size: 0.8em;
  color: white;
  text-align: left;
  font-style: italic;
  margin-top: 0;
}

.post-abstract {
  font-size: 0.9em;
  color: white;
  text-align: left;
  margin-top: 10px;
  overflow: hidden;
  /* Hides overflow content */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.post-subjects {
  display: flex;
  gap: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.post-subject-pill {
  background-color: rgb(25, 50, 100);
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.post-date-pill {
  background-color: #0d1114;
  color: white;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 0.9em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.post-link .post-header .post-date {
  text-decoration: none;
  color: inherit;
}

.post-link:hover {
  text-decoration: none;
  color: inherit;
}

.post {
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.post:hover {
  background-color: #1d2227;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}