/* Add this to ensure no sideways scrolling */
html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.feed {
  width: 100%;
  box-sizing: border-box;
  padding: 0px 10px 0 10px;
  margin-top: 48px;
}

.post {
  width: 100%;
  max-width: 600px;
  margin: auto;
  background-color: #0d1114;
  padding: 20px;
  border-radius: 0;
  height: auto;
  border-bottom: 1px solid grey;
  box-sizing: border-box;
}

/* Only show side borders if there's enough space */
@media screen and (min-width: calc(450px + 20px)) { /* 450px max-width + 20px for minimum spacing */
  .post {
    border-left: 1px solid grey;
    border-right: 1px solid grey;
  }
}

/* For tablets and small laptops */
@media screen and (min-width: 768px) {
  .post {
    width: 80%;
  }
}

/* For larger laptops and desktops */
@media screen and (min-width: 1024px) {
  .post {
    width: 80%;
  }
}
